<template>
  <div class="content">

    <div v-if="!pageLoaded">
      <loader></loader>
    </div>
    <div v-else style="margin-bottom: 100px; margin-left: 57px; margin-top:30px;">

      <p class="page-title">Bana Uygun<span> Alıcı İstekleri</span></p>
      <p class="page-sub-title">Hizmet verdiğin kategorilerle en iyi eşleşen alıcı isteklerini senin için toparladık. 😉</p>

      <div class="tabs">
        <div @click="changeTab('requests')" :class="tab === 'requests' ? 'tab-bar-active' : 'tab-bar'" style="border-top-left-radius: 5px;">
          <p class="text">Tüm Alıcı İsteklerini Göster</p>
        </div>
        <div @click="changeTab('offers')" :class="tab === 'offers' ? 'tab-bar-active' : 'tab-bar'" style="border-top-right-radius: 5px;">
          <p class="text">Sadece Teklif Verdiklerimi Göster</p>
        </div>
      </div>

      <div v-if="tab === 'requests'">

        <div class="request-box"  v-for="(request, index) in plus_requests" :key="'plus_'+index" >
          <div class="head-active">
            <div style="display: flex; margin-left: 20px; align-items: center">
              <img :src="request.buyer.avatar_url" class="avatar">
              <div style="margin-left: 15px; display: flex; flex-direction: column; height: 46px; justify-items: center; justify-content: center;">
                <p class="username">{{request.buyer.username}}</p>
                <p v-if="request.buyer.title" class="usertitle">{{request.buyer.title}}</p>
              </div>

            </div>
            <div style="display: flex; align-items: center; margin-right: 30px;">
              <p @click="report(request)" class="bildir">[sorun bildir]</p>
              <button @click="sendMessage(request)"  :disabled="buttonLoading" class="message-button">Mesaj At</button>
              <button v-if="request.pastOffer" @click="getOfferDetail(request.pastOfferDetail.id,'request')" class="teklif-button-green" style="min-width: 100px;">Teklifimi Göster</button>
              <button v-else-if="request.cancelledOffer" @click="getOfferDetail(request.cancelledOfferDetail.id,'request', 'cancelled', request)" class="teklif-button-green" style="min-width: 100px;">Teklifimi Göster</button>
              <button v-else :disabled="buttonLoading" @click="activateBuyerRequestModal(request)" class="teklif-button">Teklif Ver</button>

            </div>
          </div>
          <div class="body-plus">
            <div class="breadcrumb" v-if="categories.find(({ id }) => id === request.category_id) && categories.find(({ id }) => id === request.category_sub_id)">
              <span>{{ categories.find(({ id }) => id === request.category_id)['name'] }}</span>
              <img style="margin-left: 10px; margin-right: 10px;" src="https://gcdn.bionluk.com/site/icon/tansel_sag_ok_gri.svg" />
              <span>{{ categories.find(({ id }) => id === request.category_sub_id)['name'] }}</span>
              <template v-if="request.service_types && request.service_types.length">
                <img style="margin-left: 10px; margin-right: 10px;" src="https://gcdn.bionluk.com/site/icon/tansel_sag_ok_gri.svg"/>
                <span>{{request.service_types[0].name }}</span>
              </template>
            </div>
            <p class="body-title">{{request.title}}
              <template v-if="request.category_sub_id && (request.category_sub_id === 38 || request.category_sub_id === 61)">
                <span v-if="request.wordCount" class="extra-info-label">{{request.wordCount}} Kelime</span>
                <span class="extra-info-label" v-for="service_kind in request.service_kinds">{{service_kind.name}}</span>
                <span class="extra-info-label" v-for="service_type in request.service_types">{{service_type.name}}</span>
              </template>
            </p>
            <div>

            </div>
            <p class="body-text" v-html="request.description"></p>
            <div v-for="upload in request.uploads" style="  padding: 5px 40px 15px; margin-top: 5px; margin-bottom: 8px; display: flex; align-items: center">
              <img style="height: 27px; width: 21px; " :src="upload.icon_png">
              <a target="_blank" :href="upload.url" :download="upload.filename" style="color: #2d3640; padding-left: 8px;" >{{ upload.filename }}</a>
            </div>
          </div>

          <div class="foot">
            <div class="foot-date">{{request.approved_at | customDateFormat('dd MMMM')}}</div>
            <div class="foot-right">
              <div class="item">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-finance.svg"  onload="SVGInject(this)" class="foot-icon">
                <p class="foot-title">Bütçe: </p>
                <p class="foot-value">{{request.budget}}</p>
              </div>
              <div class="item">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-timer.svg"  onload="SVGInject(this)" class="foot-icon">
                <p class="foot-title">Süre: </p>
                <p class="foot-value">{{request.duration}}</p>
              </div>
              <div class="item">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-special-offer.svg"  onload="SVGInject(this)" class="foot-icon">
                <p class="foot-title">Teklifler: </p>
                <p class="foot-value">{{request.offers.offer_count}}</p>
              </div>

            </div>

          </div>
        </div>


        <div class="request-box"  v-for="(request, index) in requests" :key="index" >
          <div class="head-active">
            <div style="display: flex; margin-left: 20px; align-items: center">
              <img :src="request.buyer.avatar_url" class="avatar">
              <div style="margin-left: 15px; display: flex; flex-direction: column; height: 46px; justify-items: center; justify-content: center;">
                <p class="username">{{request.buyer.username}}</p>
                <p v-if="request.buyer.title" class="usertitle">{{request.buyer.title}}</p>
              </div>

            </div>
            <div style="display: flex; align-items: center; margin-right: 30px;">
              <p @click="report(request)" class="bildir">[sorun bildir]</p>
              <button @click="sendMessage(request)"  :disabled="buttonLoading" class="message-button">Mesaj At</button>
              <button v-if="request.pastOffer" @click="getOfferDetail(request.pastOfferDetail.id,'request')" class="teklif-button-green" style="min-width: 100px;">Teklifimi Göster</button>
              <button v-else-if="request.cancelledOffer" @click="getOfferDetail(request.cancelledOfferDetail.id,'request', 'cancelled', request)" class="teklif-button-green" style="min-width: 100px;">Teklifimi Göster</button>
              <button v-else :disabled="buttonLoading" @click="activateBuyerRequestModal(request)" class="teklif-button">Teklif Ver</button>

            </div>
          </div>
          <div class="body">
            <div class="breadcrumb" v-if="categories.find(({ id }) => id === request.category_id) && categories.find(({ id }) => id === request.category_sub_id)">
              <span>{{ categories.find(({ id }) => id === request.category_id)['name'] }}</span>
              <img style="margin-left: 10px; margin-right: 10px;" src="https://gcdn.bionluk.com/site/icon/tansel_sag_ok_gri.svg"/>
              <span>{{ categories.find(({ id }) => id === request.category_sub_id)['name'] }}</span>
              <template v-if="request.service_types && request.service_types.length">
                <img style="margin-left: 10px; margin-right: 10px;" src="https://gcdn.bionluk.com/site/icon/tansel_sag_ok_gri.svg"/>
                <span>{{request.service_types[0].name }}</span>
              </template>
              <span v-if="request.wordCount" class="extra-info-label">{{request.wordCount}} Kelime</span>
            </div>

            <p class="body-title">{{request.title}}</p>

            <p class="body-text" v-html="request.description"></p>
            <div v-for="upload in request.uploads" style="  padding: 5px 40px 15px; margin-top: 5px; margin-bottom: 8px; display: flex; align-items: center">
              <img style="height: 27px; width: 21px; " :src="upload.icon_png">
              <a target="_blank" :href="upload.url" :download="upload.filename" style="color: #2d3640; padding-left: 8px;" >{{ upload.filename }}</a>
            </div>

            <div style="display: flex;" v-if="request.meta_options_with_names && request.meta_options_with_names.length" class="request-meta_options">
              <div style="margin-right: 30px;" v-for="meta_option in request.meta_options_with_names">
                <p class="meta-option-title">{{meta_option.title}}</p>
                <div>
                  <p class="meta-option-values" v-for="option in meta_option.options">{{option}}</p>
                </div>
              </div>
            </div>
          </div>

          <div v-show="request.expandible" @click="expand(request, index)" class="read-more"><p>Devamını oku</p><p style="padding-top: 4px; padding-left: 4px;"><img src="https://gcdn.bionluk.com/site/cicons/ic-arrows-down.svg" onload="SVGInject(this)" class="read-more-icon"></p></div>
          <div class="foot">
            <div class="foot-date">{{ request.approved_at | customDateFormat('dd MMMM') }}</div>
            <div class="foot-right">
              <div class="item">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-finance.svg"  onload="SVGInject(this)" class="foot-icon">
                <p class="foot-title">Bütçe: </p>
                <p class="foot-value">{{request.budget}}</p>
              </div>
              <div class="item">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-timer.svg"  onload="SVGInject(this)" class="foot-icon">
                <p class="foot-title">Süre: </p>
                <p class="foot-value">{{request.duration}}</p>
              </div>
              <div class="item">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-special-offer.svg"  onload="SVGInject(this)" class="foot-icon">
                <p class="foot-title">Teklifler: </p>
                <p class="foot-value">{{request.offers.offer_count}}</p>
              </div>

            </div>

          </div>
        </div>

        <div v-if="seemorediv && !seemoreLoader" style="margin-top:20px;display: flex;justify-content: center;padding: 20px 0;width: 849px">
          <a class="see-more-text" @click="retrieveBuyerRequests('all',false)">Daha fazla göster +</a>
        </div>
        <div v-show="seemoreLoader" style="margin-top:20px;width: 849px;">
          <div class="loader" style="text-align: center; margin:0 auto;"></div>
        </div>
      </div>


      <div v-if="tab === 'offers'">

        <div class="search-user">
          <input autocomplete="off" data-lpignore="true" id="request-user-search" v-model="username" @keydown="onKeyDown" @focus="onFocus" @blur="onBlur" class="user-search-box" placeholder="Kullanıcı Ara..." />
          <div v-show="username" @click="removeUsername" class="icon-remove-container">
            <img class="icon-remove" src="https://gcdn.bionluk.com/site/cicons/ic-delete.svg" onload="SVGInject(this)"/>
          </div>
          <div v-show="!username" class="icon-search-container">
            <img class="icon-search" src="https://gcdn.bionluk.com/site/cicons/ic-search.svg" onload="SVGInject(this)"/>
          </div>
        </div>
        <p class="remove-filter-text" v-if="username && !seemoreLoader">Sadece <span class="remove-username">{{username}}</span> adlı kullanıcıya ait istekler gösteriliyor. <span @click="removeUsername" class="remove-filter">Filteyi Kaldır</span></p>

        <div class="request-box"  v-for="(request, index) in offeredRequests" :key="index" >
          <div class="head-active">
            <div style="display: flex; margin-left: 20px; align-items: center">
              <img :src="request.buyer.avatar_url" class="avatar">
              <div style="margin-left: 15px; display: flex; flex-direction: column; height: 46px; justify-items: center; justify-content: center;">
                <p class="username">{{request.buyer.username}}</p>
                <p v-if="request.buyer.title" class="usertitle">{{request.buyer.title}}</p>
              </div>
            </div>
            <div style="display: flex; align-items: center; margin-right: 30px;">
              <p @click="report(request)" class="bildir">[sorun bildir]</p>
              <button @click="sendMessage(request)"  :disabled="buttonLoading" class="message-button">Mesaj At</button>
              <button v-if="request.pastOffer" @click="getOfferDetail(request.pastOfferDetail.id,'request')" class="teklif-button-green" style="min-width: 100px;">Teklifimi Göster</button>
              <button v-else :disabled="buttonLoading" @click="activateBuyerRequestModal(request)" class="teklif-button">Teklif Ver</button>

            </div>
          </div>
          <div class="body-my">
            <div class="breadcrumb" v-if="categories.find(({ id }) => id === request.category_id) && categories.find(({ id }) => id === request.category_sub_id)">
              <span>{{ categories.find(({ id }) => id === request.category_id)['name'] }}</span>
              <img style="margin-left: 10px; margin-right: 10px;" src="https://gcdn.bionluk.com/site/icon/tansel_sag_ok_gri.svg"/>
              <span>{{ categories.find(({ id }) => id === request.category_sub_id)['name'] }}</span>
              <template v-if="request.service_types && request.service_types.length">
                <img style="margin-left: 10px; margin-right: 10px;" src="https://gcdn.bionluk.com/site/icon/tansel_sag_ok_gri.svg"/>
                <span>{{request.service_types[0].name }}</span>
              </template>
              <span v-if="request.wordCount" class="extra-info-label">{{request.wordCount}} Kelime</span>
            </div>
            <p class="body-title">{{request.title}}</p>
            <div>

            </div>
            <p class="body-text" v-html="request.description"></p>
            <div v-for="upload in request.uploads" style="  padding: 5px 40px 15px; margin-top: 5px; margin-bottom: 8px; display: flex; align-items: center">
              <img style="height: 27px; width: 21px; " :src="upload.icon_png">
              <a target="_blank" :href="upload.url" :download="upload.filename" style="color: #2d3640; padding-left: 8px;" >{{ upload.filename }}</a>
            </div>

            <div style="display: flex;" v-if="request.meta_options_with_names && request.meta_options_with_names.length" class="request-meta_options">
              <div style="margin-right: 30px;" v-for="meta_option in request.meta_options_with_names">
                <p class="meta-option-title">{{meta_option.title}}</p>
                <div>
                  <p class="meta-option-values" v-for="option in meta_option.options">{{option}}</p>
                </div>
              </div>
            </div>
          </div>

          <div v-show="request.expandible" @click="expand(request, index, true)" class="read-more"><p>Devamını oku</p><p style="padding-top: 4px; padding-left: 4px;"><img src="https://gcdn.bionluk.com/site/cicons/ic-arrows-down.svg" onload="SVGInject(this)" class="read-more-icon"></p></div>
          <div class="foot">
            <div class="foot-date">{{request.approved_at | customDateFormat('dd MMMM')}}</div>
            <div class="foot-right">
              <div class="item">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-finance.svg"  onload="SVGInject(this)" class="foot-icon">
                <p class="foot-title">Bütçe: </p>
                <p class="foot-value">{{request.budget}}</p>
              </div>
              <div class="item">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-timer.svg"  onload="SVGInject(this)" class="foot-icon">
                <p class="foot-title">Süre: </p>
                <p class="foot-value">{{request.duration}}</p>
              </div>
              <div class="item">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-special-offer.svg"  onload="SVGInject(this)" class="foot-icon">
                <p class="foot-title">Teklifler: </p>
                <p class="foot-value">{{request.offers.offer_count}}</p>
              </div>

            </div>

          </div>
        </div>

        <div v-if="seemorediv && !seemoreLoader" style="margin-top:20px;display: flex;justify-content: center;padding: 20px 0;width: 849px">
          <a class="see-more-text" @click="retrieveBuyerRequests('my_offers',false)">Daha fazla göster +</a>
        </div>
        <div v-show="seemoreLoader" style="margin-top:20px;width: 849px;">
          <div class="loader" style="text-align: center; margin:0 auto;"></div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
  import CustomSwitch from '../../../components/CustomSwitch'

  export default {
    name: "src-pages-body-workstation-buyerRequests-v3",
    components: {
	    CustomSwitch
    },

		data() {
			return {
				buttonLoading:false,
				showMyOffers:false,
				requests: [],
        offeredRequests: [],
        pageLoaded: false,
        seemorediv: false,
        totalCount: null,
        seemoreLoader: false,
        offset: 0,
        limit: 10,

        plus_requests:[],

        username:'',

        tab:'requests'
      }
		},

    methods: {
      removeUsername(){
        this.username = '';
        this.retrieveBuyerRequests('my_offers', true);
        document.getElementById('request-user-search').style.width = '170px';
      },
      onKeyDown(event){
        if(event.keyCode === 13){
          this.retrieveBuyerRequests('my_offers', true);
        }
      },
      onFocus(){
        document.getElementById('request-user-search').style.width = '100%';
      },
      onBlur(){
        if(!this.username){
          document.getElementById('request-user-search').style.width = '170px';
        }
      },
      changeTab(tab){
        if(this.tab !== tab){
          this.tab = tab;
          if(tab === 'offers'){
            this.retrieveBuyerRequests('my_offers', true);
          } else {
            this.username = '';
            this.retrieveBuyerRequests('all',true);
          }

        }
      },
      expand(request, index, isOfferedRequest=false){
        if(isOfferedRequest){
          let elems = document.getElementsByClassName("body-my");
          elems[index].style.height = request.expandible+'px';
          elems[index].style.overflow = 'visible';
          const offeredRequests = JSON.parse(JSON.stringify(this.offeredRequests));
          offeredRequests[index].expandible = false
          this.offeredRequests = offeredRequests;
        } else {
          let elems = document.getElementsByClassName("body");
          elems[index].style.height = request.expandible+'px';
          elems[index].style.overflow = 'visible';
          const requests = JSON.parse(JSON.stringify(this.requests));
          requests[index].expandible = false
          this.requests = requests;
        }

      },


      isOverflown(element) {
        if(element){
          return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
        } else {
          return false;
        }
      },

      getOfferDetail(offer_id, type, isCancelled = '', request=null) {

        this.api.seller.getOfferDetail(offer_id, false, type)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              let f = result.data.offerDetail;
              f['request'] = request;
              if(isCancelled){
                f['isCancelled'] = true
              } else {
                f['isCancelled'] = false
              }

              this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.CUSTOM_OFFER_DETAIL, info: {data: f}});

            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      activateBuyerRequestModal(request) {
        this.api.seller.retrieveGigs(null, 9999, 0, 1, 1, this.$Progress)
        .then(({data}) => {
          let result = data;

          if (result.success) {
            this.$store.commit(this.types.ACTIVE_MODAL, {
              modalType: this.Modals.CUSTOM_OFFER,
              info: {
                data: {
                  type: 'buyerRequest',
                  request,
                  gigs: result.data.gigs
                }
              }
            })
          } else {
            this.$toasted.global.errorToast({description: result.message});
          }
        })
        .catch(err => {
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
      },

      retrieveBuyerRequests(query_type = 'all', reset_offset = false) {
      	this.seemoreLoader = true;
      	if(reset_offset){
      	  this.offset = 0;
      	  this.requests = [];
          this.plus_requests = [];
      	  this.offeredRequests = [];
        }
      	if(query_type === 'all'){
      	  this.username = '';
        }

        this.api.request.list(this.limit, this.offset, query_type, this.username)
        .then(({data}) => {
          let result = data;
          this.seemoreLoader = false;
          this.pageLoaded = true;
          if (result.success) {
            this.offset += this.limit;
            this.seemorediv = result.data.seemorediv;
            if(query_type === 'all'){
              this.requests = this.requests.concat(result.data.requests);
              this.plus_requests = result.data.plus_requests;
            } else {
              this.offeredRequests = this.offeredRequests.concat(result.data.requests);
              if(result.data.requests.length === 0){
                this.seemorediv = false;
              }
            }


            this.totalCount = result.data.totalCount;
          } else {
            this.$toasted.global.errorToast({description: result.message});
          }
        })
        .catch(err => {
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
      },

      report(request){
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.COMPLAINT, info: {type: "request", externalId: request.request_id, reportTypeId: 16}});
      },
      sendMessage(request) {

        let whereFrom = this.$store.state.componentMap.page;

        if (!this.$store.state.user.uuid) {
          this.$router.push("/login");
        } else {
	        this.buttonLoading = true;
	        this.api.workstation.chatOpen(request.buyer.username, 0,1)
		        .then(({data}) => {
			        let result = data;
			        if (result.success) {
				        let conversationSendData = {
					        r_username: request.buyer.username,
					        r_avatar_url: request.buyer.avatar_url,
					        r_id: request.buyer.id,
					        r_uuid: request.buyer.uuid,
					        c_uuid: result.data.c_uuid,
                  request_id: request.request_id,
                  request_uuid: request.uuid,
					        fromWhere: 'Buyer Requests',
				        };
				        this.EventBus.$emit('externalOpenChat', conversationSendData);
			        } else {
				        this.$toasted.global.errorToast({description: result.message});
			        }
			        this.buttonLoading = false;
		        })
		        .catch(err => {
			        this.buttonLoading = false;
			        this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
		        });
        }
      },

    },
    beforeDestroy() {
      this.EventBus.$off("openNewOfferFromBuyerRequest");
    },
    watch:{

      requests: function (newVal, oldVal) {
        if(newVal.length && newVal.length !== oldVal.length){
          this.$nextTick(function () {
            // DOM updated

            const requests = JSON.parse(JSON.stringify(this.requests));

            let elems = document.getElementsByClassName("body");

            for (let i = 0; i < elems.length; i++) {
              if(this.isOverflown(elems[i])){
                requests[i]['expandible'] = elems[i].scrollHeight;
              } else {
                requests[i]['expandible'] = false;
              }
              this.requests = requests;
            }
          });
        }
      },
      offeredRequests: function (newVal, oldVal) {
        if(newVal.length && newVal.length !== oldVal.length){
          this.$nextTick(function () {
            // DOM updated

            const offeredRequests = JSON.parse(JSON.stringify(this.offeredRequests));

            let elems = document.getElementsByClassName("body-my");

            for (let i = 0; i < elems.length; i++) {
              if(this.isOverflown(elems[i])){
                offeredRequests[i]['expandible'] = elems[i].scrollHeight;
              } else {
                offeredRequests[i]['expandible'] = false;
              }
              this.offeredRequests = offeredRequests;
            }
          });
        }
      },
      'route.query.tab': function (newVal, oldVal) {
        if (newVal === 'offers') {
          if(this.route.query.username){
            this.username = this.route.query.username;
          }
          this.tab = 'offers';
          this.retrieveBuyerRequests('my_offers', true)
        }
      },
    },

    async created() {
      await this.$store.state.categoriesPromise
      this.EventBus.$on('openNewOfferFromBuyerRequest', payload => {
        this.$store.commit(this.types.DISABLE_MODAL);
        this.activateBuyerRequestModal(payload)
      });

      if (this.route.query.tab === 'offers') {
        if(this.route.query.username){
          this.username = this.route.query.username;
        }
        this.tab = 'offers';
        this.retrieveBuyerRequests('my_offers', true)
      } else {
        this.tab = 'requests';
        this.retrieveBuyerRequests('all',true)
      }

    }
	}

</script>

<style scoped lang="scss">


.request-meta_options{
  padding-top: 15px;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 10px;
  border-top: 1px solid #dadbdd;

  .meta-option-title{
    color: #95979d;
    margin-bottom: 5px;
  }

  .meta-option-values{
    color: #62646a;
    margin-bottom: 3px;

  }
}

.breadcrumb {
  font-size: 13px;
  text-align: left;
  color: #5e6b79;
  margin-top: 10px;
  margin-left: 30px;
}



  .message-button{
    width: 100px;
    height: 39px;
    border-radius: 2px;
    border: solid 1px rgba(255, 255, 255, 0.2);
    padding-bottom: 1px;
    background-color: #2d3640;

    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;

    &:hover{
      opacity: 0.8;
    }
  }

  .bildir{
    cursor: pointer;
    margin-right: 20px;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    &:hover{
      text-decoration: underline;
    }
  }
  .teklif-button{
    margin-left: 20px;
    width: 100px;
    height: 40px;
    border-radius: 2px;
    background-color: #fd4056;
    border:none;

    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.32px;
    text-align: center;
    color: #ffffff;

    &:hover{
      background-color: #FF2D55!important;
      color: #ffffff;
    }
  }
  .teklif-button-green{
    margin-left: 20px;
    width: 125px;
    height: 40px;
    border-radius: 2px;
    background-color: #00a575;
    border:none;

    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.32px;
    text-align: center;
    color: #ffffff;

    &:hover{
      opacity: 0.85;
      background-color: #00a575!important;
      color: #ffffff;
    }
  }

  .sonraki{
    cursor: pointer;
    margin-left: 30px;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.23px;
    text-align: center;
    color: #ffffff;
    &:hover{
      text-decoration: underline;
    }
  }

  .read-more{
    margin-top: 10px;
    margin-left: 30px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.38;
    color: #f75466;
    display: flex;
    align-items: center;
    height: 20px;
    padding-bottom: 10px;
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }

    .read-more-icon /deep/ {

      width: 14px;
      height: 14px;
      path {
        fill: #fd4056;
      }
    }

  }


  .request-box{
    margin-top: 30px;
    width: 843px;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.15);
    background-color: #ffffff;


    .extra-info-label
    {

      height: 21px;
      padding: 2px 10px 3px;
      border-radius: 10px;
      background-color: #26ca9a;
      font-size: 12px;
      letter-spacing: -0.22px;
      font-weight: normal;

      text-align: center;
      margin-left: 10px;
      color: #ffffff;
    }
    .head{
      width: 843px;
      height: 80px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      background-color: #8b95a1;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .head-active{
      width: 843px;
      height: 80px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      background-color: #2d3640;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .avatar{
      height: 44px;
      width: 44px;
      border: 1px solid #fff;
      border-radius: 50%;
    }

    .username{
      font-size: 18px;
      font-weight: normal;
      color: #ffffff;
    }

    .usertitle{
      margin-top: 5px;
      font-size: 14px;
      font-weight: normal;
      color: #ffffff;
    }

    .head-label{
      padding-left: 20px;
      padding-right: 20px;
      height: 28px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: normal;
      text-align: center;
      color: #ffffff;
      margin-left: 20px;
      padding-bottom: 2px;
      a{
        color: #ffffff;
      }
    }

    .green{
      background-color: #6dce6a;
    }




    .red{
      background-color: #fd4056;
    }

    .yellow{
      background-color: #f29933;
    }


    .icon-container{
      width: 30px;
      height: 30px;
      border-radius: 5px;
      background-color: #ffffff;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 20px;


      .head-eye-icon /deep/ {
        width: 14px;
        height: 14px;
        path {
          fill: #2d3640;
        }
      }

      .head-delete-icon /deep/ {
        width: 11px;
        height: 14px;
        path {
          fill: #2d3640;
        }
      }

      .head-edit-icon /deep/ {
        width: 14px;
        height: 14px;
        path {
          fill: #2d3640;
        }
      }

      &:hover{
        .head-eye-icon /deep/ {
          width: 14px;
          height: 14px;
          path {
            fill: #00a575;
          }
        }

        .head-delete-icon /deep/ {
          width: 11px;
          height: 14px;
          path {
            fill: #00a575;
          }
        }

        .head-edit-icon /deep/ {
          width: 14px;
          height: 14px;
          path {
            fill: #00a575;
          }
        }
      }


    }


    .body-my{
      height: 192px;
      overflow-y:hidden;

      .body-title{
        padding: 25px 30px 15px;
        font-size: 18px;
        font-weight: bold;
        color: #2d3640;
      }
      .body-text{
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 10px;
        font-size: 16px;
        line-height: 1.63;
        color: #5e6b79;
        word-break: break-word;
        white-space: pre-wrap;
      }
    }
    .body{
      height: 192px;
      overflow-y:hidden;

      .body-title{
        padding: 25px 30px 15px;
        font-size: 18px;
        font-weight: bold;
        color: #2d3640;
      }
      .body-text{
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 10px;
        font-size: 16px;
        line-height: 1.63;
        color: #5e6b79;
        word-break: break-word;
        white-space: pre-wrap;
      }
    }

    .body-plus{


      .body-title{
        padding: 25px 30px 15px;
        font-size: 18px;
        font-weight: bold;
        color: #2d3640;
      }
      .body-text{
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 10px;
        font-size: 16px;
        line-height: 1.63;
        color: #5e6b79;
        word-break: break-word;
        white-space: pre-wrap;
      }
    }

    .foot{
      width: 843px;
      height: 70px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      box-shadow: 0 -1px 0 0 #eaedf2;
      background-color: #f9fafb;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .foot-date{
        margin-left: 20px;
        height: 30px;
        border-radius: 5px;
        background-color: #f4f5f7;
        padding-right: 20px;
        padding-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        color: #8b95a1;
      }

      .foot-right{
        display: flex;
        margin-right: 30px;
        .item{
          margin-left: 30px;
          display: flex;
          align-items: center;

          .foot-icon /deep/ {

            width: 24px;
            height: 24px;
            path {
              fill: #8b95a1;
            }
          }

          .foot-title{
            margin-left: 10px;
            font-size: 16px;
            font-weight: normal;
            color: #2d3640;
          }

          .foot-value{
            margin-left: 5px;
            font-size: 16px;
            font-weight: 600;
            color: #2d3640;
          }

          .foot-show{
            cursor: pointer;
            margin-left: 5px;
            font-size: 16px;
            font-weight: 600;
            color: #00a575;
            &:hover{
              text-decoration: underline;
            }
          }

        }
      }
    }
  }

  .tabs{

    margin-top: 30px;
    cursor: pointer;
    display: flex; height: 70px; width: 843px;

    .tab-bar{
      width: 422px;
      height: 67px;
      border-bottom: 3px solid #e6e9ee;
      background-color: #e6e9ee;
      display: flex;
      align-items: center;
      justify-content: center;
      .text{
        font-size: 18px;
        font-weight: 500;
        color: #8b95a1;

      }

      &:hover{
        .text{
          font-size: 18px;
          font-weight: 600;
          color: #3c4654;

        }
      }
    }


    .tab-bar-active{
      width: 422px;
      height: 67px;
      background-color: #ffffff;
      border-bottom: 3px solid #2d3640;
      display: flex;
      align-items: center;
      justify-content: center;

      .text{
        font-size: 18px;
        font-weight: 600;
        color: #3c4654;

      }
    }

  }

  .page-title{
    font-size: 34px;
    font-weight: 400;
    color: #2d3640;
    span{
      font-weight: 600;
    }
  }

  .page-sub-title{
    font-size: 18px;
    margin-top: 10px;
    line-height: 1.44;
    letter-spacing: normal;
    color: #6a7685;
  }


  tbody td {
    position: relative;
    top: 2rem;
  }

  .seemore{ cursor: pointer;	font-weight: 600;text-align: center;color: #2d3640;}

  .request_type_box{
    display:inline-block; color: #777;

    padding: 4px 8px;

    font-size: 12px;
    font-weight: 400;
    background-color: #fff;

    line-height: 14px;

    border: solid 1px #ddd;
    border-radius: 3px;
    margin-right: 6px;
    margin-top: 4px;
  }

  .search-user{
    margin-top: 20px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    .user-search-box{
      min-width: 170px;
      height: 40px;
      padding-left: 20px;
      border-radius: 5px;
      border: solid 1px #eaedf2;
      background-color: white;
      font-size: 16px;
      font-weight: 600;
      color: #2d3640;
      transition: all .3s;
    }
    .icon-search-container{
      position: absolute;
      top:20px;
      right: 20px;
      .icon-search /deep/{
        width: 22px;
        height: 22px;
        path {
          fill: #8b95a1;
        }
      }
    }


    .icon-remove-container{
      cursor: pointer;
      position: absolute;
      top:20px;
      right: 20px;
      .icon-remove /deep/{
        width: 22px;
        height: 22px;
        path {
          fill: #2d3640;
        }
      }
      &:hover{
        opacity: 0.8;
      }
    }

  }

  .remove-filter-text{
    margin-top: 10px;
    font-weight: 300;
    .remove-username{
      color: #2d3640;
      font-weight: 400;
    }

    .remove-filter{
      font-weight: 400;
      color: #00a575;
      cursor: pointer;
      &:hover{
        text-decoration: underline;
      }
    }
  }

</style>
